.skill-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(20% - 1rem);
    max-width: 290px;
    min-width: 280px;
    max-height: 50px;
    padding: 4px 12px;
    background-color: var(--secondary-bg-color);
    border: var(--primary-border-color);
    border-radius: 16px;
}

.skill-brand-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.skill-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.skill-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: fill;
    border: var(--primary-border-color);
}

.skill-label {
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    color: var(--primary-text-color);
}

.skill-exp-container {
    margin-left: 20px;
    background-color: var(--tertiary-bg-color);
    border: var(--primary-border-color);
    border-radius: 8px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
}

.skill-exp {
    font-size: 0.7rem;
    font-weight: 700;
    color: var(--tertiary-text-color);
}
.sow-card {
    border-bottom: var(--primary-border-color); 
    padding: 20px 40px;
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
  }
  
  .expanded {
    /* background-color: var(--primary-expanded-bg-color);  */
  }
  
  .card-content {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .card-title {
    font-size: 1.5rem; 
    font-weight: bold;
    width: 40%;
    margin: 0px 20px;
    text-align: left;
    color: var(--primary-text-color);
  }
  
  .gradient-title {
    background: linear-gradient(180deg, #D28CCA, #FA8F57);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline; 
  }
  
  .card-description {
    display: flex;
    flex-direction: column;
    color: var(--secondary-text-color);
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 20px 20px 20px;
    width: 100%;
    max-width: 550px;
    text-align: left;
  }
  
  .sow-img-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out; 
  }
  
  .show {
    max-height: 500px; 
  }
  
  .hide {
    max-height: 0;
  }
  
  .sow-image {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 20px;
  }
  
  .expand-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  

  @media (max-width: 1200px) {
    .card-title {
      font-size: 1.8vmax;
    }

    .card-description {
      font-size: 1.4vmax;
    }

    .expand-icon {
      font-size: 2vmax !important;
    }

    .circle-icon {
      font-size: 1.6vmax !important;
    }
  }

  @media (max-width: 768px) {
    .expand-icon {
      top: 2px;
    }
  }

  @media (max-width: 600px) {
    .sow-card {
      padding: 10px 0px;
    }

    .card-title {
      margin: 0px 10px;
      min-width: 76px;
    }

    .card-description {
      margin: 10px;
      align-items: flex-start;
      width: 50%;
    }

    .show {
      width: calc(200% + 1rem);
      margin-left: calc(-100% + 1rem);
    }

    .hide {
      width: calc(200% + 1rem);
      margin-left: calc(-100% + 1rem);
    }

    .expand-icon {
      font-size: 2vmax !important;
    }

    .circle-icon {
      font-size: 1.6vmax !important;
    }
  }

  @media (max-width: 450px) {
    .show {
      margin-left: -100%;
    }

    .hide {
      margin-left: -100%;
    }
  }
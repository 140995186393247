.header-btn-container {
    padding-right: 4px;
    margin-top: 16px;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 2rem;
}

.logo-img {
    background-image: url('../Assets/Images/Logo/transparentbg.png');
    background-size: cover;
    background-position: center;
    width: 45px;
    height: 45px;
    margin-right: 16px;
    margin-left: 8px;
}

.logo-name {
    font-family: 'Inter';
    font-weight: bold;
    font-size: 1.8rem;
    color: var(--primary-text-color);
}

.header-text {
    color: var(--primary-text-color);
}

.btn-grad {background-image: linear-gradient(to right, #ff71bc 0%, #FEAC5E 51%, #C779D0 100%)}
.btn-grad {
    padding: 12px 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 48px;
    border: none;
    display: block;
    cursor: pointer;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

@media (min-width: 835px) {
    .menu-icon {
      display: none;  /* Hide menu icon on larger screens */
    }
}
  
@media (max-width: 834px) {
    .header-buttons {
        display: none;  /* Hide button navigation on small screens */
    }
    .menu-icon {
        display: block;  /* Show menu icon on small screens */
    }
}

@media (max-width: 480px) {
    .toolbar {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .logo-container {
        margin-top: 10px;
    }

    .logo {
        border-radius: 4px;
        width: 35px;
        height: 35px;
        margin-right: 12px;
    }
    
    .logo-name {
        font-size: 1.7rem !important;
    }

    .menu-icon {
        margin-top: 10px !important;
    }
}

.retro-grid-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:var(--primary-bg-color)/* #0D0D0D /#FFFFFF*/;
    perspective: 200px;
  }

  .retro-title-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 150px;
    z-index: 1;
    align-items: center;
  }

  .welcome-label {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    z-index: 1;
    color: var(--primary-text-color);
  }
  
  .retro-title {
    font-size: 6rem;
    font-weight: bold;
    margin: 10px 20px;
    color: #ff6ec7;
    text-align: center;
    z-index: 1;
    background: linear-gradient(45deg, #ff6ec7, #ffa100);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .grid-background {
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    position: absolute;
    width: 130%;
    height: 170%;
    background: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 79px,
        rgba(var(--primary-retro-grid), 0.2) 80px,
        rgba(var(--primary-retro-grid), 0.2) 81px
        /*
        rgba(0, 0, 0, 0.2) 80px,
        rgba(0, 0, 0, 0.2) 81px
        /*rgba(255, 255, 255, 0.2) 80px,
        rgba(255, 255, 255, 0.2) 81px*/

      ),
      repeating-linear-gradient(
        0deg,
        transparent,
        transparent 79px,
        rgba(var(--primary-retro-grid), 0.2) 80px,
        rgba(var(--primary-retro-grid), 0.2) 81px
        /*
        rgba(0, 0, 0, 0.2) 80px,
        rgba(0, 0, 0, 0.2) 81px
        /*rgba(255, 255, 255, 0.2) 80px,
        rgba(255, 255, 255, 0.2) 81px*/
      );
      transform: translateZ(-400px) rotateX(76deg);
      animation: moveGrid 15s linear infinite;
  }
  
  @keyframes moveGrid {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 500px;
    }
  }

@media (max-width: 1200px) {
  .welcome-label {
    font-size: 2vmax;
  }

  .retro-title {
    font-size: 7vmax;
  }
}

@media (max-width: 768px) {
  .retro-grid-container {
    height: 580px;
  }

  .grid-background {
    height: 200%;
  }

  .welcome-label {
    font-size: 2.2vmax;
  }

  .retro-title {
    font-size: 7.4vmin;
  }
}

@media (max-width: 425px) {
  .retro-grid-container {
    height: 540px;
  }

  .grid-background {
    height: 200%;
  }

  .welcome-label {
    margin-bottom: 16px;
  }
}
.contact-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    padding: 20px;
    width: calc(29.22% - 1rem);
    max-width: 320px;
    min-width: 300px;
    max-height: 150px;
    border-radius: 16px;
    background-color: var(--secondary-bg-color);
    border: var(--primary-border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
    .description-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;
      width: 100%;
      margin-left: 0px;
    }
  
    .desc-title-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 8px 0px;
    }
  
    .title {
        background: linear-gradient(180deg, #D28CCA, #FA8F57);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.3rem;
        font-weight: 500;
    }
  
    .desc-nav{
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: #61167F;
        border-radius: 12px;
        padding: 4px 16px;
        margin-top: 8px;
    }
    
    .learn-more {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.5;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
    }
  
  
  @media (max-width: 1180px) {
  }
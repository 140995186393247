.work-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
    align-items: center;
    background-color: var(--primary-bg-color);
}

.work-content-container {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
}
.contact-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
    align-items: center;
    background-color: var(--primary-bg-color);
}

.content-container {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    width: 100%;
    max-width: 1200px;
    gap: 32px;
}
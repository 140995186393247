.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --primary-bg-color: #FAFAF8;
  --secondary-bg-color: #FFFFFF;
  --tertiary-bg-color: #EFEFF0;
  --primary-text-color: black;
  --secondary-text-color: #636363;
  --tertiary-text-color: #393939;
  --primary-border-color: 1px solid #D9D9D9;
  --primary-expanded-bg-color: #f4f4f9;
  --primary-retro-grid: 0,0,0; 
}

body.dark-mode {
  --primary-bg-color: #2B2B2B;
  --secondary-bg-color: #393939;
  --tertiary-bg-color: #424242;
  --primary-text-color: white;
  --secondary-text-color: #D9D9D9;
  --tertiary-text-color: #D9D9D9;
  --primary-border-color: 1px solid #484848;
  --primary-expanded-bg-color: #393939;
  --primary-retro-grid: 255,255,255;
}

.body-content {
  background-color: var(--primary-bg-color);
  padding: 0px;
  overflow: hidden; /*Check for overflowing y-axis*/
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}

.title-underline {
  background-color: #61167F;
  border-radius: 24px;
  width: 80px;
  height: 10px;
  margin-top: 10px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
  flex-wrap: wrap;
}

.page-title {
  font-family: 'Inter';
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--primary-text-color);
}

.page-desc {
  font-family: 'Inter';
  font-size: 1.3rem;
  font-weight: 400;
  margin: 32px 0px;
  color: var(--secondary-text-color);
}

/* CSS */
.button-85 {
  font-family: 'Press Start 2P';
  padding: 1em 2em;
  margin-top: 30px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 20px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 20px;
}

@keyframes slideUp {
  from {
      opacity: 0;
      transform: translateY(120px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideUpCard {
  from {
      opacity: 0;
      transform: translateY(200px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.slide-up {
animation: slideUp 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.card-slide-up {
animation: slideUpCard 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.marquee-container {
  margin: 0;
  padding: 0;
  color: var(--primary-text-color);
  background-color: var(--tertiary-bg-color);
  font-size: 0.9rem;
  font-family: 'Inter';
  font-weight: 400;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 40px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}


@media (max-width: 1200px) {
  .button-85 {
    font-size: 1vmax;
  }
}

@media (max-width: 1024px) {
  .page-title {
    font-size: 4vmax;
  }

  .page-desc {
    font-size: 2.1vmax;
  }
}
.project-card {
  background-color: var(--secondary-bg-color);
  border: var(--primary-border-color);
  color: var(--primary-text-color);
  border-radius: 8px;
  overflow: hidden;
  width: calc(29.22% - 1rem);
  max-width: 350px;
  min-width: 300px;
  box-shadow: rgba(89, 41, 134, 0.25) 3.95px 3.95px 3.6px;
  transition: transform 0.4s;
  padding: 10px;
}

  .project-card:hover {
    transform: scale(1.02);
  }

  .project-img-container {
    background-color: var(--tertiary-bg-color);
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 20px;
    height: 200px;
  }

  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
  
  .project-info {
    padding: 16px 6px;
  }
  
  .project-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .project-title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .project-links {
    margin-top: 2px;
  }

  .project-links a {
    margin-right: 10px;
    text-decoration: none;
  }

  .project-desc-container {
    margin: 24px 0px;
    text-align: left;
    min-height: 60px;
  }

  .project-desc {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--tertiary-text-color);
  }
  
  .project-labels {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .project-labels .label {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: var(--tertiary-bg-color);
    padding: 5px 10px;
    margin: 12px 12px 0px 0px;
    border-radius: 8px;
    border: var(--primary-border-color);
    display: inline-block;
  }
  
  .label:last-child {
    margin-right: 0;
  }
  
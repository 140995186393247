.aboutme-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
    align-items: center;
    font-family: 'Inter';
    background-color: var(--primary-bg-color);
}

.content-container {
    display: flex;
    flex-direction: row !important;
}

.aboutme-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 100%;
    max-width: 1200px;
    padding: 20px 40px !important;
}

.image-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 400px;
    min-width: 180px;
    margin: 20px;
    height: auto;
    overflow: visible;
}

.img-bg-container {
    display: flex;
    max-width: 330px;
    min-width: 160px;
    height: 80%;
    padding: 0px 10px;
    border-radius: 12px;
    background-color: var(--tertiary-bg-color);
    overflow: visible;
    align-items: flex-end;
    justify-content: center;
    box-shadow: rgba(89, 41, 134, 0.25) 3.95px 3.95px 3.6px;

}

.profile-img {
    width: 100%;
    max-width: 300px;
    min-width: 160px;
    height: auto;
    object-fit: contain;
}

.desc-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 400px;
    min-width: 250px;
    padding: 20px 40px;
    justify-content: space-evenly;
}

.desc-title {
    font-size: 1.5rem;
    font-family: 'Doppio One';
    color: var(--primary-text-color);
    margin-top: 36px;
    margin-bottom: 16px;
}

.desc {
    font-size: 1rem;
    color: var(--secondary-text-color);
    letter-spacing: 1px;
    margin-right: 10px;
    margin-bottom: 16px;
}

.stats {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.years-stat-container, .project-stat-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.years, .project {
    background: linear-gradient(180deg, #D28CCA, #FA8F57); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-right: 20px;
    font-size: 3rem;
}

.year-stat-label, .project-stat-label {
    background: linear-gradient(180deg, #D28CCA, #FA8F57); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    max-width: 150px;
    margin-right: 50px;
}

.sow-container {
    display: flex;
    flex-direction: column;
    padding: 20px 40px !important;
    width: 100%;
    max-width: 1200px;
}

.sow-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 20px 0px;
    color: var(--primary-text-color);
}

@media (max-width: 1024px) {
    .desc-title {
        font-size: 2.3vmax;
    }

    .desc {
        font-size: 1.5vmax;
    }

    .years, .project {
        font-size: 5vmax;
    }

    .year-stat-label, .project-stat-label {
        font-size: 1.8vmax;
    }   

    .sow-title {
        font-size: 2.82vmax;
    }
}

@media (max-width: 600px) {
    .aboutme-container {
        flex-direction: column;
        align-items: center;
    }

    .image-container {
        min-height: 250px;
    }

    .img-bg-container {
        max-height: 180px;
    }

    .profile-img {
        max-height: 280px;
    }

    .desc-container {
        max-width: 200px;
        padding: 20px;
    }

    .desc-title {
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .desc {
        margin-bottom: 16px;
    }

    .years, .project {
        font-size: 4vmax;
        margin-right: 10px;
    }

    .year-stat-label, .project-stat-label {
        font-size: 1.6vmax;
        margin-right: 25px;
    }   

    .sow-container {
        padding: 20px 40px !important;
    }

}

@media (max-width: 425px) {
    .sow-container {
        padding: 20px !important;
    }
}

@media (max-width: 335px) {
    .desc-container {
        margin-left: 10px;
    }
}
.footer-container {
    background-color: var(--tertiary-bg-color);
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px;
    font-family: 'Inter';
    font-size: 0.9rem;
}

.footer-credits {
    text-align: right;
    color: var(--primary-text-color);
}

.credits {
    font-weight: 200;
}

.footer-credits a{
    font-weight: 600;
    text-decoration: underline;
    color: var(--primary-text-color);
    cursor: pointer;
}